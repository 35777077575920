@font-face {
  font-family: 'Spotify-Black';
  src: url('./fonts/Circular-Black.woff2');
}
@font-face {
  font-family: 'Spotify-Bold';
  src: url('./fonts/Circular-Bold.woff2');
}
@font-face {
  font-family: 'Spotify-Book';
  src: url('./fonts/Circular-Book.woff2');
}
@font-face {
  font-family: 'Spotify-Light';
  src: url('./fonts/Circular-Light.woff2');
}
body {
  margin: 0;
  font-family: 'Spotify-Light', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
