html, body {
  background-color: #131313!important;
  overflow-x: hidden;
  height: 100%;
}
#root {
  background-color:rgba(109, 109, 109, 0.56);
}
.App, #root {
  text-align: center;
  color: #FFF;
  min-height: -webkit-fill-available;
  height: min-content;
}
.App-link {
  color: #61dafb;
}
.hidden {
  display: none;
}
.title {
  font-family: 'Spotify-Black';
  padding-top: 2rem;
  font-size: 5rem;
  filter: drop-shadow(0px 4px 2px rgba(20,20,20,0.8));
}
.playlist-title {
  font-family: 'Spotify-Black';
  padding-top: 1.2rem;
  font-size: 1.5rem;
  position: fixed;
  top: 0;
  left: 1em;
  filter: drop-shadow(0px 4px 2px rgba(20,20,20,0.8));
}
.author {
  position: relative;
  top: -1.7em;
  left: -2.6em;
  word-spacing: .2em;
  letter-spacing: .1em;
  color: #ffffff0d !important;
  filter: drop-shadow(0px 4px 2px black);
}
.h-100 {
  overflow: hidden;
}
.paused {
  animation-play-state: paused !important;
}
.songPlayer {
  display: flex;
  border-radius: 1.5em;
  height: 15em;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,.05), inset 0px -20px 20px 0px rgba(0, 0, 0, 0.1), inset 0px -20px 20px 0px rgba(0, 0, 0, 0.15), inset 0px 20px 20px 0px rgba(255, 255, 255, 0.01);
  border-top: rgba(160, 160, 160, 0.03) 0.1em solid;
  border-bottom: rgba(0, 0, 0, 0.19) 0.1em solid;
  background-color: rgba(140, 140, 140, 0.05);
}
.vinyl-old {
  margin: -2em 0 -2em 0;
  border-radius: 50%;
  padding: 0.7em;
  border: 1px solid rgba(0,0,0,0.23);
  animation: spinner-border 15s linear infinite;
  position: absolute;
  left: 3em;
  -webkit-mask-image: radial-gradient(.5em at 50% 50% , #00000000 99%, black 100%);
  mask-image: radial-gradient(.5em at 50% 50% , #00000000 99%, black 100%);
  background-color: #00000038;
  filter: drop-shadow(0px 0px 60px rgba(30,30,30,0.3));
  filter: opacity(0.2);
  border-top-left-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
}
.image-container {
  height: inherit;
  position: relative;
  width: fit-content;
  margin: -1px -1px 0px 0px;
}
.vinyl {
  height: inherit;
  filter: opacity(0.8);
  border-top-left-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
  object-fit: cover;
}
.image-shadow {
  height: inherit;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
  background: linear-gradient(rgb(70 70 70 / 50%) 0%, rgb(0 0 0/ 0%) 30%,rgb(4 4 4 / 2%) 74%,rgb(10 10 10 / 34%) 90%, rgb(5 5 5 / 70%) 100%), linear-gradient(90deg, rgb(56 56 56 / 30%) 0%, rgb(0 0 0 / 0%) 30%, transparent);
}
.trackInfo {
  padding-top: 2.6em;
  padding-left: 2em;
}
.track-name, .artist-names {
  text-align: left;
  filter: drop-shadow(0px 4px 2px rgba(20,20,20,0.8));
}
.ellipsis-one-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.light-text {
  color: #8e8e8e38 !important;
}
.bolder-text, .bolder-text:hover {
  font-weight: bolder;
  color: #FFF !important;
}
.track-features {
  padding-top: 2em;
}
::-webkit-scrollbar {
  width: 2px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: rgba(50, 50, 50, 0.2);
  border-radius: 5px;
}
.close {
  color: #000 !important;
  text-shadow: 0 1px 0 #000 !important;
}
.toast-header {
  color: #fff !important;
  background-color: #585858 !important;
}
.toast {
  background-color: #ffffff24 !important;
}
.control-buttons {
  display: flow-root;
  position: relative;
  vertical-align: middle;
}
@keyframes indicator {
  from {
    background: rgb(255, 255, 255);
  }
  to {
    background: rgba(255, 255, 255, 0);
  }
}
@-webkit-keyframes indicator {
  from {
    background: rgb(255, 255, 255);
  }
  to {
    background: rgba(255, 255, 255, 0);
  }
}
.fade-indicator {
  -webkit-animation: indicator 1s ease-in-out 0s;
  -moz-animation: indicator 1s ease-in-out 0s;
  -o-animation: indicator 1s ease-in-out 0s;
  animation: indicator 0.5s 0s;
}
.fade-indicator-fast {
  animation: indicator 0.2s 0s;
}

.suggestions-container, .track-info-container, .features-container {
  border-radius: 1.5em;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,.05), inset 0px -20px 20px 0px rgba(0, 0, 0, 0.1), inset 0px -20px 20px 0px rgba(0, 0, 0, 0.15), inset 0px 20px 20px 0px rgba(255, 255, 255, 0.01);
  border-top: rgba(160, 160, 160, 0.03) 0.1em solid;
  border-bottom: rgba(0, 0, 0, 0.19) 0.1em solid;
  background-color: rgba(140, 140, 140, 0.05);
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-bottom: 1em;
  padding-top: 1em;
}

.suggested-tracks-container {
  height: 14.4em;
  overflow: hidden auto;
}

.track-suggestion {
  margin: 0.6em;
  border-radius: 1.0em;
  box-shadow: inset 0px -4px 9px 0px rgb(134 134 134 / 3%), inset 0px 4px 11px 4px rgb(0 0 0 / 19%);
  background-color: rgb(25 25 25 / 3%);
  margin-left: 0!important;
  margin-right: 0!important;
}

.suggestion-image {
  border-top-left-radius: 1.0em;
  border-bottom-left-radius: 1.0em;
  padding: 0;
}

.track-info {
  position: relative;
  overflow: hidden;
}

.additional-info {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.queue-list {
  display: flex;
  flex-direction: row;
}

.queue-item {
  border-radius: 1.5em;
}

.queue-img {
  height: 64px;
  border-radius: 15px;
  filter: opacity(0.8) blur(0.2px);
}

/* Edit buttons color on hover */
.btn:focus {
  box-shadow: none !important;
}
.btn i{
  color: #d3d3d3;
  cursor: pointer;
}
.btn i:hover {
  color: #FFF;
}
/* End button edit on hover */
.playlist {
  background-color: #28282891;
  border-radius: 10px;
  margin-bottom: 7rem;
}
.player {
  background-color: #28282891;
  max-height: 7.5rem;
  opacity: 0.94;
}
.album-cover img{
  height: 80px;
}
.album-cover {
  padding: 1.3rem 0rem 0rem 0rem;
}
.w-100 {
  border-radius: 6%;
}
.my-auto {
  width: 70%;
  border-radius: 10%;
  opacity: 0.2;
}
.song-info {
  overflow: hidden;
  white-space: nowrap;
  margin-top: 1rem;
  filter: drop-shadow(0px 4px 2px rgba(20,20,20,0.8));
}
.artist-info {
  filter: drop-shadow(0px 4px 2px rgba(20,20,20,0.8));
}
.text-muted {
  margin-bottom: .6rem;
}
.song-info a{
  color: #FFF;
}
.left-bar, right-bar {
  overflow: hidden;
}
.volume {
  width: 50%;
}
.volume-icon {
  font-size: 1.3rem;
  padding: .4rem;
}
.song-info a:hover {
  color: #FFF !important;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 1300;
}
.sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  transition: margin .25s ease-out;
}


.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;   
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  background: #1DB954;
  opacity: 1; /* Fully shown on mouse-over */
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%; 
  background: #d3d3d3;
  cursor: pointer;
  opacity: 0.1;
}

.slider::-webkit-slider-thumb:hover {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%; 
  background: #d3d3d3;
  cursor: pointer;
  opacity: 1;
}

/* CUSTOM SLIDER */
.time {
  font-size: .8rem;
  font-weight: 100;
}
/**
* Rangeslider
*/
.rangeslider {
  margin: 20px 0 10px 0;
  position: relative;
  background: #69696905;
  -ms-touch-action: none;
  touch-action: none;
  box-shadow: #00000042 0px 0px 2px 0px, #7373731c 0px 1px 1px 0px, inset 3px -1px 3px rgba(173, 173, 173, 0.13), inset 3px 0px 5px rgba(0, 0, 0, 0.38);
}
.rangeslider__fill {
  box-shadow: #00000042 0px 0px 2px 0px, #7373731c 0px 1px 1px 0px, inset 0px 1px 2px rgba(173, 173, 173, 0.21), inset 0px -2px 2px rgba(0, 0, 0, 0.56);
}
.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
}
.rangeslider:hover .rangeslider__handle, .rangeslider__handle:hover {
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  /*box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);*/
}
.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: none;
  line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
}
/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 5px;
  border-radius: 5px;
}
.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: rgb(212, 212, 212);
  border-radius: 5px;
  top: 0;
}
.rangeslider-horizontal:hover .rangeslider__fill {
  background-color: #1DB954;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
  display: none;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  display: none;
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative;
}
.rangeslider-vertical .rangeslider__labels {
  display: none;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  display: none;
}
.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: '';
  display: none;
}
.rangeslider__labels .rangeslider__label-item {
  display: none;
}